import React from 'react';
import PropTypes from 'prop-types';
import kianPic from '../images/kian.jpg';
import ContactForm from './ContactForm';

function Main({
  setWrapperRef,
  timeout,
  onCloseArticle,
  article,
  articleTimeout,
}) {
  const close = (
    <div
      className="close"
      onClick={() => {
        onCloseArticle();
      }}
    />
  );

  return (
    <div
      ref={setWrapperRef}
      id="main"
      style={timeout ? { display: 'flex' } : { display: 'none' }}
    >
      <article
        id="about"
        className={`${article === 'about' ? 'active' : ''} ${
          articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">About Me</h2>
        <span className="image kian">
          <img src={kianPic} alt="A picture of Kian" />
        </span>
        <p>
          I grew up in Dubai, UAE, and moved to the United States right before
          high school. I graduated from Harvard University's Class of 2023 with an AB
          in Computer Science. In my free time, I do standup comedy, play piano
          (currently teaching myself to play{' '}
          <a href="https://en.wikipedia.org/wiki/Boogie-woogie">
            boogie woogie style
          </a>{' '}
          ), and compete in collegiate quidditch as part of the Harvard
          Horntails.
        </p>
        {close}
      </article>

      <article
        id="contact"
        className={`${article === 'contact' ? 'active' : ''} ${
          articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">Contact</h2>
        <ContactForm />
        {/* <form
          name="contact_me"
          method="post"
          data-netlify="true"
          netlify-honeypot="gotcha-field"
          data-netlify-recaptcha="true"
        >
          <input type="hidden" name="form-name" value="contact_me" />
          <p style={{ display: 'none' }}>
            <label>
              Don’t fill this out if you are a human.:{' '}
              <input name="gotcha-field" />
            </label>
          </p>
          <div className="field half first">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" />
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input type="text" name="email" id="email" />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows="4" />
          </div>
          <div data-netlify-recaptcha="true" />
          <ul className="actions">
            <li>
              <input type="submit" className="nes-btn is-primary" />
            </li>
            <li>
              <input type="reset" value="Reset" className="nes-btn is-error" />
            </li>
          </ul>
        </form> */}
        <ul className="icons">
          <li>
            <a
              href="https://twitter.com/KianAttari"
              className="contact-icon fa-twitter"
            >
              <span className="label">Twitter</span>
            </a>
          </li>
          {/* <li>
            <a href="https://facebook.com" className="icon fa-facebook">
              <span className="label">Facebook</span>
            </a>
          </li> */}
          {/* <li>
            <a href="https://instagram.com" className="icon fa-instagram">
              <span className="label">Instagram</span>
            </a>
          </li> */}
          <li>
            <a
              href="https://github.com/kian2attari"
              className="contact-icon fa-github"
            >
              <span className="label">GitHub</span>
            </a>
          </li>
        </ul>
        {close}
      </article>
    </div>
  );
}

Main.propTypes = {
  // route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
};

export default Main;
