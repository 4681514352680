/* eslint-disable react/button-has-type */
import React from 'react';
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
  Recaptcha,
} from 'react-netlify-forms';
import { useForm } from 'react-hook-form';

function contactForm() {
  const { register, handleSubmit, formState, reset, errors } = useForm({
    mode: 'onChange',
  });
  const netlify = useNetlifyForm({
    name: 'contact_me',
    action: '/success',
    honeypotName: 'gotcha-field',
    enableRecaptcha: true,
    onSuccess: (_response, _context) => reset(),
  });
  const onSubmit = data => netlify.handleSubmit(null, data);

  const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i;

  const recaptchaKey = process.env.GATSBY_SITE_RECAPTCHA_KEY;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <NetlifyFormProvider {...netlify}>
      <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
        <Honeypot />
        {recaptchaKey && <Recaptcha siteKey={recaptchaKey} invisible />}
        {netlify.success && (
          <p className="success-message">Thanks for contacting me!</p>
        )}
        {netlify.error && (
          <p className="error-message">
            Sorry, there was an error submitting the form. Please try again.
          </p>
        )}
        <div className="field half first">
          <label htmlFor="name">Name</label>
          <input
            className="nes-input is-dark"
            type="text"
            name="name"
            id="name"
            ref={register({
              required: 'Name is required',
            })}
          />
          {errors.name && (
            <div className="error-message">{errors.name.message}</div>
          )}
        </div>
        <div className="field half">
          <label htmlFor="email">Email:</label>
          <input
            className="nes-input is-dark"
            type="email"
            name="email"
            id="email"
            ref={register({
              required: 'Email is required',
              pattern: {
                value: EMAIL_REGEX,
                message: 'Invalid email address',
              },
            })}
          />
          {errors.email && (
            <div className="error-message">{errors.email.message}</div>
          )}
        </div>
        <div className="field">
          <label htmlFor="message">Message</label>
          <textarea
            className="nes-textarea is-dark"
            name="message"
            id="message"
            rows="4"
            ref={register({ required: 'Please enter a message' })}
          />
          {errors.message && (
            <div className="error-message">{errors.message.message}</div>
          )}
        </div>
        <ul className="actions">
          <li>
            <input
              type="submit"
              className={`nes-btn is-primary ${!formState.isValid &&
                'is-disabled'}`}
              disabled={!formState.isValid}
            />
          </li>
          <li>
            <input type="reset" value="Reset" className="nes-btn is-error" />
          </li>
        </ul>
      </NetlifyFormComponent>
    </NetlifyFormProvider>
  );
}

export default contactForm;
