import React from 'react';
import PropTypes from 'prop-types';
import ReactTypingEffect from '../vendor/react-typing-effect/src/lib';

const SubText = ({ text, withAnimation }) =>
  withAnimation ? (
    <ReactTypingEffect
      text={text}
      speed={80}
      typingDelay={1200}
      eraseDelay={10800}
      className="SubText"
    />
  ) : (
    <span className="SubText">{text}</span>
  );

SubText.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  withAnimation: PropTypes.bool,
};

export default SubText;
