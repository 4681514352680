import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';
import WelcomeText from './WelcomeText';
import 'nes.css/css/nes.min.css';

const Header = ({ timeout, onOpenArticle }) => (
  <header id="header" style={timeout ? { display: 'none' } : {}}>
    <nav>
      <ul className="nes-container is-rounded is-dark">
        <li>
          <AwesomeButton
            type="primary"
            role="button"
            action={() => {
              onOpenArticle('about');
            }}
          >
            About
          </AwesomeButton>
        </li>
        <li>
          <AwesomeButton
            type="primary"
            role="button"
            action={() => {
              navigate('/portfolio');
            }}
          >
            Portfolio
          </AwesomeButton>
        </li>

        <li>
          <AwesomeButton
            type="primary"
            role="button"
            action={() => {
              onOpenArticle('contact');
            }}
          >
            Contact
          </AwesomeButton>
        </li>
      </ul>
    </nav>
    <div className="content nes-container is-rounded is-dark with-title is-centered">
      <h3 className="title">Hi!</h3>
      <WelcomeText />
    </div>
  </header>
);

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
};

export default Header;
