import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ timeout }) => (
  <footer id="footer" style={timeout ? { display: 'none' } : {}}>
    <p className="copyright">Kian Attari &copy; 2023 </p>
  </footer>
);

Footer.propTypes = {
  timeout: PropTypes.bool,
};

export default Footer;
