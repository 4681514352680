import React from 'react';

import PropTypes from 'prop-types';

import Layout from './BaseLayout';

import Header from '../components/Header';
import Main from '../components/Main';
import Footer from '../components/Footer';

const BaseLayoutSetup = props => {
  const {
    children,
    location,
    loading,
    isArticleVisible,
    handleOpenArticle,
    timeout,
    articleTimeout,
    article,
    handleCloseArticle,
    setWrapperRef,
  } = props;

  return (
    <Layout location={location}>
      <div
        className={`body ${loading} ${
          isArticleVisible ? 'is-article-visible' : ''
        }`}
      >
        <div id="wrapper">
          {children || (
            <>
              <Header onOpenArticle={handleOpenArticle} timeout={timeout} />
              <Main
                isArticleVisible={isArticleVisible}
                timeout={timeout}
                articleTimeout={articleTimeout}
                article={article}
                onCloseArticle={handleCloseArticle}
                setWrapperRef={setWrapperRef}
              />
            </>
          )}
          <Footer timeout={timeout} />
        </div>
        <div id="bg" />
      </div>
    </Layout>
  );
};

BaseLayoutSetup.propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.objectOf(PropTypes.bool),
  }),
  loading: PropTypes.string,
  isArticleVisible: PropTypes.bool,
  handleOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
  articleTimeout: PropTypes.bool,
  article: PropTypes.string,
  handleCloseArticle: PropTypes.func,
  setWrapperRef: PropTypes.func,
};

export default BaseLayoutSetup;
