import * as React from 'react';
import { Link } from 'gatsby';
import SubText from './SubText';
import HandRaised from '../images/svg/hand_raised.svg';

const WelcomeText = () => (
  <div className="WelcomeBlock">
    <div className="SubTextDiv">
      <SubText text="I'm Kian. A Harvard newgrad." withAnimation={false} />
    </div>
    <div className="WaveGuyDiv">
      <img src={HandRaised} className="WaveGuy" />
    </div>
  </div>
);

export default WelcomeText;
